// src/pages/OurTools.js
import React from 'react';
import './OurTools.css';
import deNovoDesignVideo from '../videos/de_novo_design.mp4';
import biosimilarVideo from '../videos/biosimilar_design.mp4';
import optimizationVideo from '../videos/optimization.mp4';
import deNovoDesignVideoEs from '../videos/de_novo_design_es.mp4';
import biosimilarVideoEs from '../videos/biosimilar_design_es.mp4';
import optimizationVideoEs from '../videos/optimization_es.mp4';
import { useTranslation } from 'react-i18next';

const OurTools = () => {
  const { t, i18n } = useTranslation();
  let language = ''
  if (i18n.language === 'es') {
    language = 'es'
  }
  else if (i18n.language === 'en') {
    language = 'en'
  }
  else {
    language = navigator.language.split('-')[0];
  }

  const currentDeNovoDesignVideo = language === 'es' ? deNovoDesignVideoEs : deNovoDesignVideo;
  const currentBiosimilarVideo = language === 'es' ? biosimilarVideoEs : biosimilarVideo;
  const currentOptimizationVideo = language === 'es' ? optimizationVideoEs : optimizationVideo;

  return (
    <div className="our-tools-container">
      <h1>{t('our_tools.title')}</h1>

      <section id="de-novo-design" className="tool-section">
        <h2>{t('our_tools.denovo_design_title')}</h2>
        <p>{t('our_tools.denovo_design_text')}</p>
        <div className="video-container">
          <video controls key={language + '-denovo'}>
            <source src={currentDeNovoDesignVideo} type="video/mp4" />
            {t('our_tools.denovo_design_error')}
          </video>
        </div>
      </section>

      <section id="biosimilars" className="tool-section">
        <h2>{t('our_tools.biosimilars_title')}</h2>
        <p>{t('our_tools.biosimilars_text')}</p>
        <div className="video-container">
          <video controls key={language + '-biosimilars'}>
            <source src={currentBiosimilarVideo} type="video/mp4" />
            {t('our_tools.biosimilars_error')}
          </video>
        </div>
      </section>

      <section id="optimization" className="tool-section">
        <h2>{t('our_tools.optimization_title')}</h2>
        <p>{t('our_tools.optimization_text')}</p>
        <div className="video-container">
          <video controls key={language + '-optimization'}>
            <source src={currentOptimizationVideo} type="video/mp4" />
            {t('our_tools.optimization_error')}
          </video>
        </div>
      </section>

      <section id="another" className="tool-section">
        <h2>{t('our_tools.another_approach_title')}</h2>
        <p>{t('our_tools.another_approach_text')}</p>
      </section>
    </div>
  );
};

export default OurTools;