import React from 'react';
import './Home.css';
import logo from '../images/company-logo.png';
import image1 from '../images/reduce_cost.png';
import image2 from '../images/image_accuracy.png';
import image3 from '../images/image_growth.png';
import image4 from '../images/image_collaboration.png';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t, i18n } = useTranslation();

  return (
  <div className="home-container">
    <img src={logo} alt="ProtForge Logo" className="logo" />
    <div className="text-container">
      <h1 className="main-title">ProtForge</h1>
      <h2 className="subtitle">edit the cure.</h2>
      <h1 className="main-title">{t('home.title')}</h1>
    </div>
    <div className="row">
      <img src={image1} alt={t('home.subtitle1')} className="row-image" />
      <div className="row-text">
        <h2>{t('home.subtitle1')}</h2>
        <p>{t('home.text1')}</p>
      </div>
    </div>
    <div className="row reverse">
      <div className="row-text">
        <h2>{t('home.subtitle2')}</h2>
        <p>{t('home.text2')}</p>
      </div>
      <img src={image2} alt={t('home.subtitle2')} className="row-image" />
    </div>
    <div className="row">
      <img src={image3} alt={t('home.subtitle3')} className="row-image" />
      <div className="row-text">
        <h2>{t('home.subtitle3')}</h2>
        <p>{t('home.text3')}</p>
      </div>
    </div>
    <div className="row reverse">
      <div className="row-text">
        <h2>{t('home.subtitle4')}</h2>
        <p>{t('home.text4')}</p>
      </div>
      <img src={image4} alt={t('home.subtitle4')} className="row-image" />
    </div>
  </div>
  )
};

export default Home;