import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../images/company-logo.png';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  
  return (
    <header>
      <div className="logo-title">
        <img src={logo} alt="ProtForge Logo" />
        <h1><Link to="/">ProtForge</Link></h1>
      </div>
      <nav>
        <ul className="nav-links">
          <li className="dropdown">
            <Link to="/about" className="dropbtn">{t('header.about_us')}</Link>
            <div className="dropdown-content">
              <a href="/about">{t('header.about_us')}</a>
              <a href="/about/our-blog">{t('header.our_blog')}</a>
            </div>
          </li>
          <li className="dropdown">
            <Link to="/our-tools" className="dropbtn">{t('header.our_tools')}</Link>
            <div className="dropdown-content">
              <a href="/our-tools#de-novo-design">{t('header.denovo_design')}</a>
              <a href="/our-tools#biosimilars">{t('header.biosimilars')}</a>
              <a href="/our-tools#optimization">{t('header.optimization')}</a>
            </div>
          </li>
          <li><Link to="/case-studies">{t('header.case_studies')}</Link></li>
          <li className="dropdown">
            {/* Make the main Contact link point to General contact */}
            <Link to="/contact/general" className="dropbtn">{t('header.contact')}</Link>
            <div className="dropdown-content">
              <Link to="/contact/general">{t('header.general_contact')}</Link>
              <Link to="/contact/collaboration">{t('header.ask_for_a_collaboration')}</Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className="language-dropdown">
        <button className="dropbtn">{t('header.language')}</button>
        <div className="dropdown-content">
          <button onClick={() => changeLanguage('en')}>English</button>
          <button onClick={() => changeLanguage('es')}>Español</button>
        </div>
      </div>
    </header>
  );
}

export default Header;