// src/pages/PrivacyPolicy.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <h1>{t('privacy_policy.title')}</h1>
      <p>{t('privacy_policy.introduction')}</p>
      <h2>{t('privacy_policy.understanding_terms.title')}</h2>
      <p>{t('privacy_policy.understanding_terms.description')}</p>
      <h2>{t('privacy_policy.defined_terms.title')}</h2>
      <ul>
        <li><strong>{t('privacy_policy.defined_terms.account')}</strong>: {t('privacy_policy.defined_terms.account_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.affiliate')}</strong>: {t('privacy_policy.defined_terms.affiliate_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.company')}</strong>: {t('privacy_policy.defined_terms.company_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.cookies')}</strong>: {t('privacy_policy.defined_terms.cookies_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.country')}</strong>: {t('privacy_policy.defined_terms.country_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.device')}</strong>: {t('privacy_policy.defined_terms.device_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.personal_data')}</strong>: {t('privacy_policy.defined_terms.personal_data_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.service')}</strong>: {t('privacy_policy.defined_terms.service_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.service_providers')}</strong>: {t('privacy_policy.defined_terms.service_providers_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.usage_data')}</strong>: {t('privacy_policy.defined_terms.usage_data_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.website')}</strong>: {t('privacy_policy.defined_terms.website_description')}</li>
        <li><strong>{t('privacy_policy.defined_terms.you')}</strong>: {t('privacy_policy.defined_terms.you_description')}</li>
      </ul>
      <h1>{t('privacy_policy.information_collection.title')}</h1>
      <h2>{t('privacy_policy.information_collection.personal_data.title')}</h2>
      <p>{t('privacy_policy.information_collection.personal_data.description')}</p>
      <ul>
        <li>{t('privacy_policy.information_collection.personal_data.email')}</li>
        <li>{t('privacy_policy.information_collection.personal_data.first_last_name')}</li>
        <li>{t('privacy_policy.information_collection.personal_data.phone')}</li>
        <li>{t('privacy_policy.information_collection.personal_data.address')}</li>
      </ul>
      <h2>{t('privacy_policy.information_collection.usage_data.title')}</h2>
      <p>{t('privacy_policy.information_collection.usage_data.description')}</p>
      <h2>{t('privacy_policy.tracking_cookies.title')}</h2>
      <p>{t('privacy_policy.tracking_cookies.description')}</p>
      <h1>{t('privacy_policy.data_usage.title')}</h1>
      <p>{t('privacy_policy.data_usage.description')}</p>
      <h1>{t('privacy_policy.sharing_information.title')}</h1>
      <p>{t('privacy_policy.sharing_information.description')}</p>
      <h1>{t('privacy_policy.data_retention_security.title')}</h1>
      <p>{t('privacy_policy.data_retention_security.description')}</p>
      <h1>{t('privacy_policy.children_privacy.title')}</h1>
      <p>{t('privacy_policy.children_privacy.description')}</p>
      <h1>{t('privacy_policy.external_links.title')}</h1>
      <p>{t('privacy_policy.external_links.description')}</p>
      <h1>{t('privacy_policy.policy_updates.title')}</h1>
      <p>{t('privacy_policy.policy_updates.description')}</p>
      <h1>{t('privacy_policy.contact.title')}</h1>
      <p>{t('privacy_policy.contact.description')}</p>
    </div>
  );
};

export default PrivacyPolicy;