import React, { useState } from 'react';
import './CollaborationForm.css';
import { useTranslation } from 'react-i18next';

const CollaborationForm = () => {
  const { t, i18n } = useTranslation();
  let language = ''
  if (i18n.language === 'es') {
    language = 'es'
  }
  else if (i18n.language === 'en') {
    language = 'en'
  }
  else {
    language = navigator.language.split('-')[0];
  }

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    collaborationType: 'de novo',
    validationChoice: '',
    additionalInfo: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidationChange = (e) => {
    setFormData({
      ...formData,
      validationChoice: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setFormSubmitted(true);
      } else {
        alert('Failed to send message');
      }
    } catch (error) {
      alert('Error: ' + error.toString());
    }
  };

  return (
    <div className="collaboration-container">
      <h2>{t('collaboration_form.collaboration_title')}</h2>
      {formSubmitted ? (
        <div className="success-message">
          <h2>{t('collaboration_form.message_sent')}</h2>
          <p>{t('collaboration_form.next_days_reply')}</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">{t('collaboration_form.name')}</label>
            <input type="text" id="name" name="name" required onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="company">{t('collaboration_form.company_name')}</label>
            <input type="text" id="company" name="company" required onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('collaboration_form.email')}</label>
            <input type="email" id="email" name="email" required onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="collaborationType">{t('collaboration_form.collaboration_type')}</label>
            <select id="collaborationType" name="collaborationType" required onChange={handleChange}>
              <option value="de novo">{t('collaboration_form.type_denovo')}</option>
              <option value="biosimilar">{t('collaboration_form.type_biosimilar')}</option>
              <option value="optimization">{t('collaboration_form.type_optimization')}</option>
            </select>
          </div>
          <div className="form-group">
            <p>{t('collaboration_form.service_type')}</p>
            <div className="radio-group">
              <input
                type="radio"
                id="just-design"
                name="validation"
                value="design"
                checked={formData.validationChoice === 'design'}
                onChange={handleValidationChange}
              />
              <label htmlFor="just-design">{t('collaboration_form.service_design')}</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                id="design-development"
                name="validation"
                value="development"
                checked={formData.validationChoice === 'development'}
                onChange={handleValidationChange}
              />
              <label htmlFor="design-development">{t('collaboration_form.service_design_development')}</label>
            </div>
            <div className="radio-group">
              <input
                type="radio"
                id="design-development-validation"
                name="validation"
                value="validation"
                checked={formData.validationChoice === 'validation'}
                onChange={handleValidationChange}
              />
              <label htmlFor="design-development-validation">{t('collaboration_form.service_design_development_validation')}</label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="additionalInfo">{t('collaboration_form.more_info')}</label>
            <textarea id="additionalInfo" name="additionalInfo" onChange={handleChange}></textarea>
          </div>
          <button type="submit">{t('collaboration_form.submit')}</button>
        </form>
      )}
    </div>
  );
};

export default CollaborationForm;