// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import futureGoalsImage from '../images/future-goals.jpg';
import ethicsImage from '../images/ethics.jpg';
import collaborationImage from '../images/collaboration.jpg';
import techOverviewImageEN from '../images/tech-overview.png';  // Pre-import English image
import techOverviewImageES from '../images/tech-overview-es.jpg'; // Pre-import Spanish image

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  let language = ''
  if (i18n.language === 'es') {
    language = 'es'
  }
  else if (i18n.language === 'en') {
    language = 'en'
  }
  else {
    language = navigator.language.split('-')[0];
  }

  // Select the correct image based on the language
  const techOverviewImage = language === 'es' ? techOverviewImageES : techOverviewImageEN;

  return (
    <div className="about-us-container">
      <section className="mission">
        <div className="oval-container">
          <h2>{t('about_us.mission_title')}</h2>
          <p>{t('about_us.mission_text')}</p>
        </div>
      </section>

      <section className="technology-overview">
        <h2>{t('about_us.technology_overview_title')}</h2>
        <div className="text">
          <p>{t('about_us.technology_overview_text')}</p>
        </div>
        <div className="image">
          <img src={techOverviewImage} alt="Technology Overview" />
        </div>
      </section>

      <section className="applications">
        <h2>{t('about_us.applications')}</h2>
        <div className="applications-container">
          {/* Application categories */}
          {[
            'therapeutics', 'diagnostics', 'research_tools', 'vaccine_development', 
            'agricultural_improvements', 'veterinary', 'cosmetics', 'food', 
            'environment', 'biodefense', 'industrial', 'immunoassays'
          ].map(category => (
            <div key={category} className="application-category">
              <h3>{t(`about_us.applications_${category}`)}</h3>
              <p>{t(`about_us.applications_${category}_examples`)}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="future-goals">
        <h2>{t('about_us.future_goals_title')}</h2>
        <div className="row centered">
          <div className="text" style={{ fontSize: '2.8rem', lineHeight: '1.9' }}>
            <p>{t('about_us.future_goals_text')}</p>
          </div>
          <div className="image">
            <img src={futureGoalsImage} alt="Future Goals" />
          </div>
        </div>
      </section>

      <section className="ethics">
        <h2>{t('about_us.future_ethics_title')}</h2>
        <div className="row centered">
          <div className="image">
            <img src={ethicsImage} alt="Ethics" />
          </div>
          <div className="text" style={{ fontSize: '2.8rem', lineHeight: '1.9' }}>
            <p>{t('about_us.future_ethics_text')}</p>
          </div>
        </div>
      </section>

      <section className="collaboration">
        <h2>{t('about_us.future_collaboration_title')}</h2>
        <div className="row centered">
          <div className="text" style={{ fontSize: '2.8rem', lineHeight: '1.9' }}>
            <p>{t('about_us.future_collaboration_text')}</p>
          </div>
          <div className="image">
            <img src={collaborationImage} alt="Collaboration" />
          </div>
        </div>
      </section>

      <section className="technology-overview">
        <div className="text">
          <h2>
            <Trans i18nKey="about_us.check_blog">
              Do you want to see our latest news? Take a look at <Link to="/about/our-blog" className="underline">our blog</Link>
            </Trans>
          </h2>
        </div>
      </section>
    </div>
    
  );
};

export default AboutUs;