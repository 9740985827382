import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import GeneralContact from './pages/GeneralContact';
import CollaborationForm from './pages/CollaborationForm';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import CaseStudies from './pages/CaseStudies';
import OurTools from './pages/OurTools';
import OurBlog from './pages/OurBlog';
import DynamicPage from './pages/DynamicPage'; // New dynamic page component
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './public/locales/en/translation.json';
import translationES from './public/locales/es/translation.json';

i18n
  .use(LanguageDetector) // Automatically detects user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: translationEN },
      es: { translation: translationES }
    },
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  });

const App = () => {
  const { t } = useTranslation();
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/about/our-blog" element={<OurBlog />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact/general" element={<GeneralContact />} />
          <Route path="/contact/collaboration" element={<CollaborationForm />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/our-tools" element={<OurTools />} />
          <Route path="/pages/:label" element={<DynamicPage />} /> {/* New route for dynamic pages */}
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;