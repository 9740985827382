// src/pages/CaseStudies.js
import React, { useState } from 'react';
import './CaseStudies.css';
import biosimilarOptimizationPDF1 from '../pdfs/in_silico_optimization.pdf';
import biosimilarOptimizationPDF1Es from '../pdfs/in_silico_optimization_es.pdf';
import biosimilarOptimizationPDF2 from '../pdfs/biosimilar_optimization_study1.pdf';
import deNovoDesignPDF from '../pdfs/in_silico_design_report.pdf';
import deNovoDesignPDFEs from '../pdfs/de_novo_optimization_es.pdf';
import { useTranslation } from 'react-i18next';

const CaseStudies = () => {
  const { t, i18n } = useTranslation();
  let language = ''
  if (i18n.language === 'es') {
    language = 'es'
  }
  else if (i18n.language === 'en') {
    language = 'en'
  }
  else {
    language = navigator.language.split('-')[0];
  }

  const biosimilarInsilicoPDF = language === 'es' ? biosimilarOptimizationPDF1Es : biosimilarOptimizationPDF1;
  const denovoInsilicoPDF = language === 'es' ? deNovoDesignPDFEs : deNovoDesignPDF;

  const [selectedOption, setSelectedOption] = useState('biosimilarOptimization');

  return (
    <div className="case-studies-container">
      <div className="button-container">
        <button
          className={selectedOption === 'biosimilarOptimization' ? 'active' : ''}
          onClick={() => setSelectedOption('biosimilarOptimization')}
        >
          {t('case_studies.optimization_title')}
        </button>
        <button
          className={selectedOption === 'deNovoDesign' ? 'active' : ''}
          onClick={() => setSelectedOption('deNovoDesign')}
        >
          {t('case_studies.denovo_title')}
        </button>
      </div>

      {selectedOption === 'biosimilarOptimization' ? (
        <div className="pdf-list">
          <h2>{t('case_studies.optimization_subtitle')}</h2>
          <h2>{t('case_studies.optimization_text')}</h2>
          <ul>
            <li><a href={biosimilarInsilicoPDF} target="_blank" rel="noopener noreferrer">{t('case_studies.optimization_insilico')}</a></li>
            <li><a href={biosimilarOptimizationPDF2} target="_blank" rel="noopener noreferrer">{t('case_studies.optimization_study1')}</a></li>
            {/* Add more studies as needed */}
          </ul>
        </div>
      ) : (
        <div className="de-novo-content">
          <h2>{t('case_studies.denovo_subtitle')}</h2>
          <h2>{t('case_studies.denovo_text')}</h2>
          <p>{t('case_studies.denovo_explanation')}</p>
          <a href={denovoInsilicoPDF} target="_blank" rel="noopener noreferrer">{t('case_studies.denovo_insilico')}</a>
        </div>
      )}
    </div>
  );
};

export default CaseStudies;