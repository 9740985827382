import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // If there's a hash, scroll to the specific element smoothly
      const element = document.getElementById(hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    } else {
      // If no hash, scroll instantly to the top of the page
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' // This effectively disables scrolling animation
      });
    }
  }, [pathname, hash]);

  return null;
};

export default ScrollToTop;