import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './DynamicPage.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';

const DynamicPage = () => {
  const { t, i18n } = useTranslation();
  let language = ''
  if (i18n.language === 'es') {
    language = 'es'
  }
  else if (i18n.language === 'en') {
    language = 'en'
  }
  else {
    language = navigator.language.split('-')[0];
  }

  const { label } = useParams(); // Get the label from the URL
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    // Fetch the content dynamically based on the label
    console.log(label)
    fetch(`${process.env.REACT_APP_API_URL}/pages/${label}`)
    //fetch(`http://localhost:5000/pages/${label}`)
      .then((response) => response.json())
      .then((data) => setPageData(data))
      .catch((error) => console.error('Error fetching page data:', error));
  }, [label]);

  if (!pageData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="dynamic-page-container">
      <h1>{language === 'es' ? pageData.title_es : pageData.title_en}</h1>
      <h2>{language === 'es' ? pageData.subtitle_es : pageData.subtitle_en}</h2>
      <div className="image-text-wrapper">
        {pageData.image && (
          <img src={pageData.image} alt={language === 'es' ? pageData.title_es : pageData.title_en} className="dynamic-page-image" />
        )}
        <div className="dynamic-page-content">
          <div dangerouslySetInnerHTML={{ __html: language === 'es' ? pageData.content_es : pageData.content_en }} />
        </div>
      </div>
    </div>
  );
};

export default DynamicPage;